import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

export class StudentTile extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'student-tile': true
    });
  }

  get uri () {
    return [
      this.props.collectionTileImage?.file?.url + '?fm=jpg',
      'w=800',
      'h=800',
      'fit=fill',
      'q=70'
    ].join('&');
  }

  render = () => (
    <Link className={this.className} to={this.props.slug} data-sal="fade" data-sal-delay={ this.props.index * 50 } data-sal-duration="800">
      <div className="student-tile__image">
        { this.props.collectionTileImage?.file?.url && (
          <img width="800" height="800" src={this.uri} alt={`${this.props.firstname} ${this.props.lastname}`} />
        )}
      </div>
      <div className="student-tile__label">
        <span>{ this.props.firstname }</span>
        <span>{ this.props.lastname }</span>
      </div>
    </Link>
  )
}