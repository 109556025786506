import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { Header } from '../../components/header';
import { StudentTile } from '../../components/student-tile';
import { Link } from 'gatsby';

import Previous from '../../components/header/previous.inline.svg';
import Next from '../../components/header/next.inline.svg';

export default class Page extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  get className () {
    return classNames({
      'homepage': true
    });
  }

  get website () {
    return this.props.pageContext.website;
  }

  get grade () {
    return this.props.pageContext.grade;
  }

  get year () {
    return this.props.pageContext.year;
  }

  get tiles () {
    return this.grade.collections;
  }

  get next () {
    return this.grade.grade > 1 ? this.grade.grade - 1 : 5;
  }

  get previous () {
    return this.grade.grade < 5 ? this.grade.grade + 1 : 1;
  }

  get shareImageUrl () {
    var url = 'https:' + this.website.socialMediaImage.file.url;
    if (this.grade.tileImages && this.grade.tileImages.length > 0) {
      if (this.grade.tileImages[0].file?.url) {
        url = this.grade.collectionImages[0].file?.url + '?w=1200&h=630&fit=fill&f=top';
      }
    }
    return url;
  }

  componentDidMount () {
    if (window.innerWidth < 1024) return;

    this.columns = new Array(4).fill(0).map(_ => []);
    this.tilesDOM = Array.from(document.querySelectorAll('.student-tile '));
    this.tilesDOM.forEach((tile, index) => {
      let position = index % 4;
      this.columns[position].push(tile);
    });

    let transform = `translateY(50px)`;
    this.columns[0].forEach(tile => tile.style.transform = transform);
    this.scroll();

    window.addEventListener('scroll', this.scroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.scroll);
  }

  scroll = () => {
    const update = () => {
      let percentage = window.scrollY / document.body.scrollHeight;
  
      var pixels = (-percentage * 300) - 40;
      var transform = `translateY(${pixels}px)`;
      this.columns[1].forEach(tile => tile.style.transform = transform);

      pixels = (percentage * 180);
      transform = `translateY(${pixels}px)`;
      this.columns[2].forEach(tile => tile.style.transform = transform);

      pixels = (-percentage * 120) - 40;
      transform = `translateY(${pixels}px)`;
      this.columns[3].forEach(tile => tile.style.transform = transform);
    }
    
    // if(this.grade.grade !== 1)
    requestAnimationFrame(update);
  }

  render = () => (
    <>
     <Helmet>
        <title>{`${this.grade.grade} - ${this.website.title}`}</title>
        <meta name="description" content={this.website.description} />
        <meta property="og:image" content={'https:' + this.website.socialMediaImage.file.url} />
      </Helmet>
      <Header website={this.website} />
      <header className="grade__header">
        <div className="grade__header__arrow grade__header__arrow--left">
          <Link to={`/${this.website.language}/${this.year}/${this.previous}`}><Previous /></Link>
        </div>
        <div className="grade__header__number"><span>{this.grade.grade}</span></div>
        <div className="grade__header__arrow grade__header__arrow--right">
          <Link to={`/${this.website.language}/${this.year}/${this.next}`}><Next /></Link>
        </div>
      </header>
      <section className="student-tile__container">
        { this.tiles.map((tile, index) => <StudentTile key={tile.id} {...tile} index={index} />) }
      </section>
    </>
  )
}